import React from 'react';
import dayjs from 'dayjs';

const AwardItem = ({ honor, date, organizer }) => {
    const year = dayjs(date).format('YYYY');
    return (
        <div className='w-full'>
            <h3 className='w-full uppercase font-semibold text-gray-50'>{honor}</h3>
            <p className='text-gray-100'>{year}</p>
            <p className='text-gray-100'>{organizer}</p>
        </div>
    );
}

export default AwardItem;