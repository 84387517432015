import { useStaticQuery, graphql } from "gatsby";

const useFeaturedProjects = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
  query useFeaturedDevProjectsQuery {
    allMarkdownRemark(
      sort: {order: DESC, fields: [frontmatter___projectdate]}
      filter: {frontmatter: {category: {eq: "software"}, featuredpost: {eq: true}}}
      limit: 6
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            featuredimage
            templateKey
            projectdate(formatString: "MMMM DD, YYYY")
            featuredpost
          }
        }
      }
    }
  }
  
  `);
  return allMarkdownRemark;
};

export default useFeaturedProjects;
