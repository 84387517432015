import React from 'react'
// import PreviewCompatibleImage from "modules/common/components/preview-compatible-image";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const FeaturedProject = ({ slug, childImageSharp, title }) => {
    const image = getImage(childImageSharp)
    return (
        <Link to={slug} className='p-4 hover:bg-gray-200 rounded-md'>
            {image ? (
                <GatsbyImage className="mb-4" image={image} alt={`thumbnail for ${title}`} />
            ) : null}
            <h3 className='text-xl font-semibold sm:font-medium'>
                {title}
            </h3>
        </Link>
    );
}

export default FeaturedProject;