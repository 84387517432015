import React from 'react'
import { PaperAirplaneIcon } from '@heroicons/react/24/solid'
import MdContent from '../../common/components/md-content';

const Contact = ({ spiel }) => {
    return (
        <section id={'contact'} className='w-full flex justify-center bg-brand text-gray-50' >
            <div className='max-w-4xl px-6 sm:px-4 py-12 flex flex-col items-center justify-center'>
                <h3 className='text-3xl text-center font-semibold uppercase mb-4'>LET&apos;S TALK</h3>
                <div className='mb-6 text-center'>
                    <MdContent content={spiel} className={'sm:text-lg'} />
                </div>
                <div className='max-w-xs'>
                    <a href="mailto:daniel.simmons.ritchie@gmail.com" className='flex items-center transition-all duration-300 justify-center text-base uppercase px-3 py-2 border-2 border-white rounded-md mb-6 hover:bg-gray-50 hover:text-brand w-full'><PaperAirplaneIcon className='h-4 w-4 mr-2' /> Email me</a>
                    <div className='flex justify-center items-center uppercase font-light font-gray-200'>
                        <a className='px-3 border-r hover:underline ' href="https://twitter.com/Daniel_SR" target="_blank" rel="noreferrer">X</a>
                        <a className='px-3 border-r  hover:underline' href="https://github.com/SimmonsRitchie" target="_blank" rel="noreferrer">Github</a>
                        <a className='px-3 hover:underline' href="https://www.linkedin.com/in/danielsimmonsritchie/" target="_blank" rel="noreferrer">Linkedin</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;