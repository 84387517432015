import React from 'react';
import Markdown from 'markdown-to-jsx';


const MdContent = ({ content, className }) => {
    return (
        <Markdown
            className={`markdown ${className}`}
        >
            {content}
        </Markdown>
    );
}

export default MdContent;