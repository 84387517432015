import React from "react";
import scrollToElem from "../../common/api/scrollToElem";
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image'

const Hero = ({ name, tagline }) => {
  const { allFile } = useStaticQuery(graphql`
  query AustinImgQuery {
    allFile(filter: {relativePath: {glob: "austin-blue-light.png"}}) {
      edges {
        node {
          id
          absolutePath
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
        }
      }
    }
  }
  `);


  const imageData = allFile.edges[0].node.childImageSharp.fluid

  return (
    <BackgroundImage Tag="div" fluid={imageData} className="bg-brand">
      <div className="flex flex-col pt-32 pb-12 px-4 gap-y-2 mt-16">
        <h1 className="uppercase text-white font-semibold block text-3xl md:text-5xl text-center mb-2 p-0">
          {name}
        </h1>
        <h2 className="text-center text-xl md:text-2xl text-gray-50">{tagline}</h2>
        <div className="flex items-center justify-center my-10 ">
          <button onClick={() => scrollToElem("#software")} className="text-gray-50 text-sm uppercase px-6 py-2 border-2 border-gray-50 block transition-all duration-300 rounded-md hover:bg-gray-50 hover:text-brand">View my work</button>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default Hero;
