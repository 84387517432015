import React from "react";
import Layout from "modules/common/components/layout";
import Hero from "modules/index/components/hero";
import SEO from "modules/common/components/seo";
import AboutMe from "./about-me";
import FeaturedProjects from "./featured-projects";
import FeaturedAwards from "./featured-awards";
import { DISPLAY_NAMES } from "../../common/api/constants";
import Contact from "./contact";

const IndexPage = ({ projects, name, tagline, profileImage, aboutMe, softwareIntro, awardsIntro, contactIntro }) => {
  return (
    <Layout onHomePg>
      <SEO keywords={[`simmons-ritchie`, `engineer`, 'web developer', 'journalist']} title="Home" />
      <div className="w-full flex flex-col">
        <Hero name={name} tagline={tagline} />
        <AboutMe profileImage={profileImage} aboutMe={aboutMe} />
        <FeaturedProjects
          hed={DISPLAY_NAMES.DEV}
          spiel={softwareIntro}
          data={projects}
        />
        <FeaturedAwards spiel={awardsIntro} />
        <Contact spiel={contactIntro} />
      </div>
    </Layout >
  );
};

export default IndexPage;
