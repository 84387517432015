/* eslint-disable react/display-name */
import React from "react";
import { Link } from "gatsby";
import ViewAllButton from "./viewall-button";
import MdContent from "../../common/components/md-content";
import FeaturedProject from "./featured-project";

const FeaturedProjects = ({ hed, data, spiel }) => {
  const categoryPageLink = `/software`;
  return (
    <section id={"software"} className="w-full flex justify-center even:bg-gray-200">
      <div className="max-w-4xl px-2 sm:px-4 py-12">
        <h2 className="text-3xl font-semibold text-brand uppercase mb-4 text-center">
          <Link to={categoryPageLink}>{hed}</Link>
        </h2>
        <div className="mb-8 w-full flex justify-center">
          <MdContent content={spiel} className={'text-center sm:text-lg'} />
        </div>
        <div className="w-full flex flex-col gap-5 mb-8 sm:grid sm:grid-cols-3">
          {data.map((project) => (<FeaturedProject
            key={project.id}
            childImageSharp={project.childImageSharp}
            slug={project.fields.slug}
            title={project.frontmatter.title}
          />
          ))}
        </div>
        <div className="w-full flex justify-center align-middle">
          <Link to={categoryPageLink}>
            <ViewAllButton />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default FeaturedProjects;
