import React from 'react';
import Section from '../../common/components/section';
import MdContent from '../../common/components/md-content';


const AboutMe = ({ profileImage, aboutMe }) => {
	return (
		<Section id="about">
			<div className="flex flex-col items-center gap-y-8 py-4">
				<h3 className='text-3xl font-semibold text-brand uppercase'>About me</h3>
				<img src={profileImage} alt="Profile headshot" className="rounded-full w-44 object-center ring-4 ring-brand" />
				<MdContent content={aboutMe} className="text-center max-w-xl sm:text-lg" />
			</div>
		</Section>

	);
}

export default AboutMe;