import React from 'react';
import AwardItem from './award-item';
import useAwards from '../hooks/useFeaturedAwards';
import { Link } from 'gatsby';
import MdContent from '../../common/components/md-content';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image'


const FeaturedAwards = ({ spiel }) => {
	const awards = useAwards().slice(0, 6);
	const { allFile } = useStaticQuery(graphql`
	query MilfordImgQuery {
	  allFile(filter: {relativePath: {glob: "milford-dark.png"}}) {
		edges {
		  node {
			id
			absolutePath
			  childImageSharp {
				fluid(quality: 90, maxWidth: 1920) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
		  }
		}
	  }
	}
	`);
	const imageData = allFile.edges[0].node.childImageSharp.fluid

	return (
		<BackgroundImage Tag="section" fluid={imageData} className="bg-black">
			<div className='w-full flex justify-center'>
				<div className='max-w-4xl px-4 py-12 flex flex-col justify-center items-center '>
					<h3 className='text-3xl font-semibold text-gray-100 uppercase mb-6'>Awards</h3>
					<MdContent content={spiel} className='text-center text-gray-100 sm:text-lg mb-8' />
					<div className='w-full grid grid-cols-1 sm:grid-cols-2 gap-y-6 gap-x-6 text-gray-50 mb-12'>
						{
							awards.map(({ honor, date, organizer, notes }) => (
								<AwardItem
									key={honor + organizer + date}
									honor={honor}
									date={date}
									organizer={organizer}
									notes={notes}
								/>
							))
						}
					</div>
					<Link to='/awards' >
						<button className="uppercase font-semibold transition-all duration-300 bg-red-800 rounded-md border-2 border-red-800 hover:bg-red-700 hover:border-red-700 text-red-50 px-4 py-1.5">
							View all
						</button>
					</Link>
				</div>
			</div>
		</BackgroundImage>
	);
}

export default FeaturedAwards;