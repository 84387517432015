import React from "react";
import { graphql } from "gatsby";
import IndexPage from "modules/index/components/index-page";
import useFeaturedProjects from "../modules/index/hooks/useFeaturedProjects";

function IndexPageWrapper({ data: { allFile, markdownRemark: { frontmatter: { name, tagline, profileImage, aboutMe, softwareIntro, awardsIntro, contactIntro } } } }) {
  // Note: For mysterious reasons, useFeaturedProject's query fails in prod
  // when incorporated as a page query but works fine as a static query.
  const allMarkdownRemark = useFeaturedProjects()
  const imgHash = {}
  allFile.edges.forEach(edge => {
    const imgPath = edge.node.absolutePath.replace(/^.*\/static/, '')
    imgHash[imgPath] = edge.node.childImageSharp
  })
  const projects = allMarkdownRemark.edges.map(edge => {
    const { featuredimage } = edge.node.frontmatter
    edge.node['childImageSharp'] = imgHash[featuredimage];
    return edge.node
  })
  return (
    <IndexPage projects={projects} name={name} tagline={tagline} profileImage={profileImage} aboutMe={aboutMe} softwareIntro={softwareIntro} awardsIntro={awardsIntro} contactIntro={contactIntro} />
  );
}

export const pageQuery = graphql`
  query IndexPageQuery {
    markdownRemark(fileAbsolutePath: {glob: "**/pages/index.md"}) {
      id
      frontmatter {
        profileImage
        aboutMe
        softwareIntro
        awardsIntro
        contactIntro
        name
        tagline
      }
    }
    allFile(
      filter: {absolutePath: {glob: "**/img/*.{jpg,jpeg,png}"}}
    ) {
      edges {
        node {
          id
          absolutePath
          childImageSharp {
            gatsbyImageData(
              width: 600,
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;



export default IndexPageWrapper;
