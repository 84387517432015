import React from 'react'

const ViewAllButton = () => {
    return (
        <button className="uppercase font-semibold transition-all duration-300 text-red-800 rounded-md border-2 border-red-800 hover:bg-red-800 hover:text-gray-50 px-4 py-1.5">
            View all
        </button>
    );
}

export default ViewAllButton;