import { useStaticQuery, graphql } from "gatsby";

const useAwards = () => {
  const { allPagesJson: { nodes } } = useStaticQuery(graphql`
    query FeaturedAwardsQuery {
      allPagesJson {
        nodes {
          award {
            notes
            category
            date
            honor
            organizer
          }
        }
      }
    }
  `);
  return nodes[0].award
};

export default useAwards;
